import React from "react"
import { useStateIfMounted } from "use-state-if-mounted"
import "../components/layout.css"
import Seo from "../components/SEOComponents/Seo"

import ServiceSection from "../components/ServiceSection/ServiceAgreement"
import { NavigationBar } from "../components/NavigationBar/parent"
import Footer from "../components/FooterComponents/Footer"

import { MainPageJson, ServiceJson, PathJson } from "../components/location"
import { LayoutTextPage } from "../components/LayoutTextPage/parent"
import NewServiceAgreement from "../components/ServiceSection/NewServiceAgreement"
import { TrueServiceAgreement } from "../components/ServiceSection/TrueServiceAgreement"
import { useLocation } from "@reach/router"

function Service() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const ServiceContent = ServiceJson()
  const { Title } = ServiceContent || {}
  const { pathname } = useLocation()
  const isEnglish = pathname.includes("/en")

  return (
    <div className="maxwidth">
      <Seo title="service-agreement" />
      <NavigationBar />
      <LayoutTextPage title={Title}>
        {/* <ServiceSection props={ServiceContent} propsLink={PathContent} /> */}
        {/* <NewServiceAgreement /> */}
        <TrueServiceAgreement isEnglish={isEnglish} />
      </LayoutTextPage>
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default Service
