import React, { useEffect, useState } from "react"
import axios from "axios"
import "./styling.css"

export const TrueServiceAgreement = ({ isEnglish }) => {
  const [data, setData] = useState([])
  const [modifiedHtml, setModifiedHtml] = useState("")
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    const url = process.env.GATSBY_ROOT_URL_ENV
    try {
      setLoading(true)
      const { data } = await axios.get(`${url}/api/tnc/category_for_web`)
      console.log(data)
      setData(data.tnc)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      {data.map((item, index) => {
        const { category_eng, category_idn, details_v2 } = item || {}
        const { content_eng, content_idn } = details_v2 || {}
        const title = isEnglish ? category_eng : category_idn
        const content = isEnglish ? content_eng : content_idn
        const isLastItem = index === data.length - 1
        const numberedTitle = isLastItem ? title : `${index + 1}. ${title}`
        return (
          <div key={index} className="tnc-section">
            <h1 className={isLastItem ? "tnc-title-last" : "tnc-title"}>
              {numberedTitle}
            </h1>
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className="tnc-contents"
            ></div>
          </div>
        )
      })}
    </div>
  )
}

export default TrueServiceAgreement
