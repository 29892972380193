import React from "react"
import Point1 from "./Point1"
import Point2 from "./Point2"
import Point3 from "./Point3"
import Point4 from "./Point4"
import Point5 from "./Point5"
import Point6 from "./Point6"
import Point7 from "./Point7"
import Point8 from "./Point8"
import Point9 from "./Point9"
import Point10 from "./Point10"
import { ContentP } from "./ServiceStyle"
import { Prohibited } from "./Prohibited"

function ServiceAgreement({ props, propsLink, status }) {
  return (
    <>
      <Point1 props={props} />
      <Point2 props={props} />
      <Point3 props={props} propsLink={propsLink} />
      <Point4 props={props} />
      <Point5 props={props} propsLink={propsLink} />
      <Point6 props={props} />
      <Point7 props={props} propsLink={propsLink} />
      <Point8 props={props} />
      <Point9 props={props} />
      <Point10 props={props} propsLink={propsLink} />
      <Prohibited props={props} />
      <ContentP mtop="50px">{props.Version}</ContentP>
    </>
  )
}

export default ServiceAgreement
