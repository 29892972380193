import styled from "styled-components"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"

export const SectionStyled = styled.section`
  padding-top: 130px;
  padding-bottom: 100px;
`

export const TitleContainer = styled(Container)`
  max-width: 800px;
`

export const TitleH1 = styled.h1`
  font-family: "Typefez-black";
  font-size: 50px;
  color: #909098;
  text-align: center;
  margin-bottom: 3rem;
  @media (max-width: 576px) {
    font-size: 38px;
  }
`

export const MainDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(15, 44, 87, 0.12);
  border-radius: 15px;
  padding: 50px;
  @media (max-width: 576px) {
    padding: 50px 20px 50px 20px;
  }
`

export const PointH3 = styled.h3`
  margin-bottom: 20px;
  font-size: 24px;
  font-family: "Typefez-med";
  color: #196e97;
`

export const ContentLi = styled.li`
  margin-bottom: 20px;
  text-align: justify;
  font-family: "Typefez-reg";
  font-size: ${p => p.fsize};
`
export const ContentP = styled.p`
  text-align: justify;
  font-family: "Typefez-reg";
  padding-left: ${p => p.pleft};
  margin: ${p => p.margin};
`

export const Bold = styled.strong`
  font-family: "Typefez-med";
`

export const CustomLink = styled(Link)`
  color: #da649f;
  :hover {
    color: #da649f;
  }
`

export const TableDiv = styled.div`
  border: 1px solid black;
  padding: 20px;
  max-width: 500px;
  margin-top: ${p => p.mtop || "100px"};
  margin-left: 30px;
  @media (max-width: 576px){
    margin-left: 0px;
  }
`

export const TableTitle = styled.h5`
  font-family: "Typefez-med";
  font-size: 14px;
  margin-bottom: 20px;
`

export const TableLi = styled.li`
  margin-bottom: 10px;
  text-align: justify;
  font-family: "Typefez-reg";
  font-size: 14px;
`