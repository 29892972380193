import styled from "styled-components"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"

export const SectionStyled = styled.section`
  margin-bottom: 3rem;
  margin-top: 3rem;
`

export const TitleContainer = styled(Container)`
  max-width: 800px;
  margin-bottom: 100px;
  @media (max-width: 576px) {
    margin-bottom: 50px;
  }
`

export const TitleH1 = styled.h2`
  font-family: "Typefez-black";
  color: #909098;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 50px;
`

export const ContentContainer = styled(Container)`
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

export const PointH3 = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  font-family: "Typefez-med";
  color: #196e97;
`

export const ContentLi = styled.li`
  margin-bottom: 20px;
  text-align: justify;
  font-family: "Typefez-reg";
`
export const ContentP = styled.p`
  text-align: justify;
  font-family: "Typefez-reg";
  padding-left: ${p => p.pleft};
  margin-top: ${p => p.mtop};
`

export const Bold = styled.strong`
  font-family: "Typefez-med";
`

export const CustomLink = styled(Link)`
  color: #da649f;
  :hover {
    color: #da649f;
  }
`
