import React, { useEffect, useState } from "react"
import axios from "axios"
import "./service.css"

function NewServiceAgreement() {
  const [data, setData] = useState({})
  const { content_eng, content_idn } = data || {}
  const [modifiedHtml, setModifiedHtml] = useState("")
  const [loading, setLoading] = useState(false)

  const FetchData = async () => {
    const url = process.env.GATSBY_ROOT_URL_ENV
    try {
      setLoading(true)
      const { data } = await axios.get(
        `${url}/api/tnc/get_tnc_of_remittance_service_for_web`
      )
      setData(data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const modifyHtmlContent = htmlContent => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlContent, "text/html")

    // Apply custom ordered list class to each ordered list
    doc.querySelectorAll("ol").forEach(ol => {
      ol.classList.add("custom-ordered-list")
    })

    // Add 'first-layer-item' class to only the first layer li elements
    const firstLayerList = doc.querySelector("ol.custom-ordered-list")
    if (firstLayerList) {
      Array.from(firstLayerList.children).forEach(child => {
        if (child.tagName === "LI") {
          child.classList.add("first-layer-item")

          // Find nested 'ol' within the first-layer 'li' and add 'second-layer-item' class to their 'li' children
          const secondLayerLists = child.querySelectorAll("ol")
          secondLayerLists.forEach(nestedOl => {
            nestedOl.querySelectorAll("li").forEach(nestedLi => {
              nestedLi.classList.add("second-layer-item")
            })
          })
        }
      })
    }

    return doc.body.innerHTML
  }

  useEffect(() => {
    if (content_idn) {
      const modifiedContent = modifyHtmlContent(content_idn)
      setModifiedHtml(modifiedContent)
    }
  }, [content_idn])

  useEffect(() => {
    FetchData()
  }, [])
  // console.log(data)
  console.log("modifiedHtml:", modifiedHtml)
  return <div dangerouslySetInnerHTML={{ __html: modifiedHtml }}></div> // INI YG HARUS DI RENDER
  // return <div>{content_eng}</div> // INI UNTUK TAU NAMA CLASS TIAP HTML TAG NYA
}

export default NewServiceAgreement
